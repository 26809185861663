import {
  PasswordInput as MantinePasswordInput,
  PasswordInputProps as MantinePasswordInputProps
} from '@mantine/core';
import cx from 'clsx';
import isNil from 'lodash/isNil';
import React, { ReactNode } from 'react';

import { PadlockIcon } from '@/common/components/Icons/PadlockIcon';
import { useUncontrolled } from '@/common/hooks/useUncontrolled';

import {
  FormInputWrapper,
  IAmFormInputRenderProps
} from '../InputWrapper/FormInputWrapper';
import classes from './FormPasswordInput.module.css';

export interface FormPasswordInputProps
  extends Omit<
      MantinePasswordInputProps,
      | 'defaultValue'
      | 'value'
      | 'onChange'
      | 'labelProps'
      | 'descriptionProps'
      | 'withAsterisk'
      | 'autoComplete'
      | 'size'
    >,
    IAmFormInputRenderProps<HTMLInputElement> {
  defaultValue?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  sanitiseValue?: (value: string) => string;

  withIcon?: boolean;
  /**
   * @deprecated use leftSection instead
   */
  icon?: ReactNode;
  /**
   * @deprecated use leftSectionWidth instead
   */
  iconWidth?: number;
  autoComplete?: 'current-password' | 'new-password';
}

export const FormPasswordInput = React.forwardRef<
  HTMLInputElement,
  FormPasswordInputProps
>(
  (
    {
      value: valueProp,
      defaultValue,
      sanitiseValue,
      onChange,
      required,
      onFocus,
      selectAllTextOnFocus,
      autoFocus,
      descriptionProps,
      inputContainer,
      showSkeleton,
      labelProps,
      error,
      hideErrorMessage,
      size,
      icon,
      withIcon,
      className,
      iconWidth,
      leftSectionWidth: leftSectionWidthProp,
      leftSection: leftSectionProp,
      ...rest
    },
    forwardedRef
  ) => {
    const [value, handleChange] = useUncontrolled<
      string,
      React.ChangeEvent<HTMLInputElement>
    >({
      value: valueProp,
      defaultValue,
      finalValue: null,
      resolveValue: (e) => e.target.value,
      sanitiseValue,
      onChange
    });

    const hasIcon = !!icon || !!withIcon;

    const leftSection = !isNil(leftSectionProp) ? leftSectionProp : icon;
    let leftSectionWidth = leftSectionWidthProp ?? iconWidth;
    if (hasIcon && isNil(leftSectionWidth) && size === 'lg') {
      leftSectionWidth = 40;
    }
    return (
      <FormInputWrapper<HTMLInputElement>
        ref={forwardedRef}
        autoFocus={autoFocus}
        onFocus={onFocus}
        error={error}
        hideErrorMessage={hideErrorMessage}
        selectAllTextOnFocus={selectAllTextOnFocus}
        descriptionProps={descriptionProps}
        inputContainer={inputContainer}
        showSkeleton={showSkeleton}
        labelProps={labelProps}
        size={size}
      >
        {(p, ref) => (
          <MantinePasswordInput
            ref={ref}
            size={size}
            value={value}
            onChange={handleChange}
            withAsterisk={required}
            leftSectionWidth={leftSectionWidth}
            data-with-icon={hasIcon ? 'true' : 'false'}
            data-size={size}
            className={cx(className, classes.input)}
            leftSection={
              !!leftSection ? (
                leftSection
              ) : withIcon ? (
                <PadlockIcon />
              ) : undefined
            }
            {...rest}
            {...p}
          />
        )}
      </FormInputWrapper>
    );
  }
);
