import { FormFieldErrors } from '@/common/models/form/FormFieldErrors';
import { Guid } from '@/common/models/Guid';
import {
  OperationResult,
  OperationResultType
} from '@/common/models/OperationResult';
import { SiteUser } from '@/common/models/SiteUser';
import { mapArray } from '@/common/utils/ArrayFunctions';
import { ThuziEnvironments } from '@/front/utils/Thuzi';

export interface ThuziSignInRequest {
  siteId: string | Guid;
  brandId: string;
  eventId: string;
  token: string;
  environment: ThuziEnvironments;
}

type ThuziSignInData = {
  userSessionId: Guid;
  profileId: Guid;
};
export class ThuziSignInResponse extends OperationResult<
  OperationResultType,
  ThuziSignInData
> {
  constructor(props: Partial<ThuziSignInResponse>) {
    super(props);
    Object.assign(this, props);
    if (this.data) {
      this.data.userSessionId = Guid.valueOrEmpty(this.data.userSessionId);
      this.data.profileId = Guid.valueOrEmpty(this.data.profileId);
    }
  }
}

export interface OAuthUserSignInRequest {
  token: string;
  userId: string | Guid;
}

export interface OAuthUserSignInResponse {
  success: boolean;
}

export interface UserSignInRequest {
  siteId: string;
  email: string;
  password?: string;
}

export interface UserSignInConfirmEmailRequest {
  siteId: string;
  email: string;
  rememberMe?: boolean;
}

export interface UserSignInCodeRequest {
  email: string;
  code: string;
  siteId: string;
}

export interface UserPreSignUpRequest {
  siteId: Guid;
  email: string;
  triggerFormId?: Guid;
  fields: Record<string, any>;
}

export interface UserSignUpRequest extends UserPreSignUpRequest {
  code: string;
  triggerFormId?: Guid;
  password: string;
}

export enum UserPreSignUpResult {
  Success = 'Success',
  UserExists = 'UserExists',
  EmailInvalid = 'EmailInvalid',
  InvalidDetails = 'InvalidDetails',
  UnHandledError = 'UnHandledError'
}

export class UserPreSignUpResponse extends OperationResult<UserPreSignUpResult> {
  formFieldErrors?: FormFieldErrors[];
  constructor(props: Partial<UserPreSignUpResponse>) {
    super(props);
    Object.assign(this, props);
    this.formFieldErrors = mapArray(props.formFieldErrors, (x) => x);
  }
}

export enum UserSignUpResult {
  Success = 'Success',
  UnHandledError = 'UnHandledError',
  NoSite = 'NoSite',
  UserExists = 'UserExists',
  EmailInvalid = 'EmailInvalid',
  UserLinkedToAnotherProfile = 'UserLinkedToAnotherProfile',
  NoUser = 'NoUser',
  IncorrectCode = 'IncorrectCode'
}

export class UserSignUpResponse extends OperationResult<UserSignUpResult> {
  formFieldErrors?: FormFieldErrors[];
  constructor(props: Partial<UserSignUpResponse>) {
    super(props);
    Object.assign(this, props);
    this.formFieldErrors = mapArray(props.formFieldErrors, (x) => x);
  }
}

export enum UserSignInResult {
  Success = 'Success',
  EmailError = 'EmailError'
}

export class UserSignInResponse extends OperationResult<
  UserSignInResult,
  SiteUser
> {
  constructor(props: Partial<UserSignInResponse>) {
    super(props);
    Object.assign(this, props);
    if (this.data) {
      this.data = new SiteUser(props.data);
    }
  }
}

export interface UserForgotPasswordRequest {
  email: string;
  siteId: string;
  returnUrl?: string;
}

export interface UserResetPasswordRequest {
  code: string;
  siteId: string;
  password: string;
}

export interface ValidateUserEmailRequest {
  email: string;
  sendEmail?: boolean;
  triviaGameId?: Guid;
  cardId?: Guid;
}

export interface ValidateUserEmailResponseData {
  email: string;
  hasAccount: boolean;
  needsSignInUserId?: Guid;
}
export class ValidateUserEmailResponse extends OperationResult<
  OperationResultType,
  ValidateUserEmailResponseData
> {
  constructor(props: Partial<ValidateUserEmailResponse>) {
    super(props);
    Object.assign(this, props);
    if (this.data)
      this.data.needsSignInUserId = Guid.valueOrUndefined(
        props.data?.needsSignInUserId
      );
  }
}
