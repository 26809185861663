import { FC, ForwardedRef, forwardRef, useEffect, useRef } from 'react';
import isEqual from 'react-fast-compare';
import {
  DefaultValues,
  FieldValues,
  FormProvider,
  useForm,
  useFormContext,
  UseFormReturn,
  useFormState
} from 'react-hook-form';

import { useDebouncedCallback } from '@/common/hooks/debounce';
import { useEvent } from '@/common/hooks/useEvent';

import { FormHiddenSubmit } from '../FormHiddenSubmit';
import { Form, FormProps } from './Form';

export interface FormHookProps<TFieldValues extends FieldValues = FieldValues>
  extends Omit<FormProps, 'onSubmit' | 'defaultValue'> {
  defaultValues: DefaultValues<TFieldValues>;
  onSubmit: (values: TFieldValues) => void;
  onInit?: (methods: UseFormReturn<TFieldValues>) => void;
  withHiddenSubmit?: boolean;
  stopPropagationOnSubmit?: boolean;
  onDirtyChange?: (isDirty: boolean) => void;
}

function FormHookElement<TFieldValues extends FieldValues = FieldValues>(
  {
    defaultValues,
    children,
    onSubmit,
    onInit,
    onDirtyChange,
    withHiddenSubmit = true,
    stopPropagationOnSubmit,
    ...rest
  }: FormHookProps<TFieldValues>,
  ref?: ForwardedRef<HTMLFormElement>
) {
  const hasRunInit = useRef(false);
  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
    defaultValues,
    shouldFocusError: true
  });

  if (!hasRunInit.current) {
    hasRunInit.current = true;
    onInit?.(methods);
  }

  const hasOnDirtyChange = !!onDirtyChange;

  return (
    <FormProvider {...methods}>
      <Form
        {...rest}
        ref={ref}
        onSubmit={(e) => {
          if (stopPropagationOnSubmit) {
            e.stopPropagation();
          }
          methods.handleSubmit(onSubmit)(e);
        }}
      >
        {hasOnDirtyChange && <DirtyListener onChange={onDirtyChange} />}
        {children}
        {withHiddenSubmit && <FormHiddenSubmit />}
      </Form>
    </FormProvider>
  );
}

type DirtyListenerProps = {
  onChange: (value: boolean) => void;
};
const DirtyListener: FC<DirtyListenerProps> = ({ onChange }) => {
  const formState = useFormState();
  const formContext = useFormContext();

  const defaultValuesRef = useRef(formState.defaultValues ?? {});
  const isDirtyRef = useRef(false);

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      const currentForm = formContext.getValues();
      defaultValuesRef.current = currentForm;
      formContext.reset(currentForm);
    }
  }, [formState.isSubmitSuccessful]);

  const handleChange = useEvent(onChange);

  const isDirtyCallback = useDebouncedCallback((currentValues: any) => {
    const currentIsDirty = !isEqual(defaultValuesRef.current, currentValues);
    if (currentIsDirty !== isDirtyRef.current) {
      isDirtyRef.current = currentIsDirty;
      handleChange(currentIsDirty);
    }
  });

  useEffect(() => {
    const subscription = formContext.watch(isDirtyCallback);
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return null;
};

export const FormHook = forwardRef(FormHookElement) as <
  TFieldValues extends FieldValues = FieldValues
>(
  props: FormHookProps<TFieldValues> & { ref?: ForwardedRef<HTMLFormElement> }
) => ReturnType<typeof FormHookElement>;
