import isNil from 'lodash/isNil';
import { FieldValues } from 'react-hook-form';

import { IAmFormHookField } from '../FormHookField';
import { FormEmailInput, FormEmailInputProps } from './FormEmailInput';

export interface FormHookEmailInputProps<TFieldValues extends FieldValues = any>
  extends IAmFormHookField<TFieldValues>,
    Omit<
      FormEmailInputProps,
      'required' | 'name' | 'value' | 'defaultValue' | 'error' | 'disabled'
    > {}

export function FormHookEmailInput<TFieldValues extends FieldValues = any>(
  props: FormHookEmailInputProps<TFieldValues>
) {
  const { hook, onBlur, onChange, ...rest } = props;
  return (
    <FormEmailInput
      {...rest}
      required={hook.required}
      name={hook.field.name}
      ref={hook.field.ref}
      disabled={hook.disabled}
      onBlur={(e, v) => {
        onBlur?.(e, v);
        hook.field.onBlur();
      }}
      onChange={(e, v) => {
        hook.field.onChange(v);
        onChange?.(e, v);
      }}
      value={!isNil(hook.field.value) ? hook.field.value : ''}
      error={hook.showError ? hook.errorMessage : undefined}
    />
  );
}
